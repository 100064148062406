


































import { Component, Prop, Vue } from 'vue-property-decorator';
import InviteSecondPartnerAdmin from '@rs-911/components/modal/InviteSecondPartnerAdmin.vue';
import { AgGridVue } from 'ag-grid-vue';
import { Coupon } from '@shared/models/coupon';
import { Routes } from '@rs-911/router/routes';
import BtnCellRenderer from '@shared/plugins/btn-cell-renderer';

@Component({
  components: {
    AgGridVue,
    InviteSecondPartnerAdmin
  }
})
export default class SecondPartnerManagement extends Vue {
  rowData = null;
  routes = Routes;
  frCoupon!: Coupon;
  frameworkComponents = {
    btnCellRenderer: BtnCellRenderer
  };
  created() {
    this.getSecondPartnerAdmins();
  }

  async getSecondPartnerAdmins() {
    const result = await this.$store.dispatch('auth/getSecondPartnerAdmins');
    this.rowData = result.map((item: any) => {
      return { ...item };
    });
  }

  defaultColDefs = {
    resizable: true,
    flex: 1,
    sortable: true,
    filter: true,
    cellStyle: {textAlign: 'left'}
  };

  columnDefs= [
    { headerName: 'Full Name', field: 'fullName' },
    { headerName: 'Email', field: 'email'},
    {
      field: 'email',
      headerName: 'Revoke Admin',
      cellRenderer: 'btnCellRenderer',
      sortable: false,
      filter: false,
      cellRendererParams: {
        clicked: (field: any) => {
          this.$store.dispatch('auth/revokeAdmin', { email: field }).then(x => {
            this.getSecondPartnerAdmins();
            this.$store.dispatch('notify', {title: 'Admin Revoked', body: `${field} is revoked from admin rights`, variant: 'primary'});
          })
          .catch(err => {
            this.getSecondPartnerAdmins();
            this.$store.dispatch('notify', {title: 'Error', body: 'Error occured while trying to revoke admin rights to' + field, variant: 'danger'});
          });
        }
      },
    }
  ];
}

