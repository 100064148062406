import Vue from 'vue';

export default Vue.extend({
  methods: {
    btnClickedHandler() {
      (this as any).params.clicked((this as any).params.value);
    }
  },
  template: `
        <span>
            <b-button class="btn-sm" @click="btnClickedHandler()">Revoke Admin</b-button>
        </span>
    `
});
