















































































import { Component, Vue } from 'vue-property-decorator';
import { required, email } from 'vuelidate/lib/validators';
Component.registerHooks(['validations']);

@Component
export default class InviteSecondPartnerAdmin extends Vue {
  showOverlay = false;
  searchString = '';
  list: Array<string> = [];
  form = {
    email: null,
    firstName: null,
    lastName: null
  }

  validations() {
    return {
      form: {
        email: {
          required,
          email
        },
        firstName: {
          required
        },
        lastName: {
          required
        }
      }
    };
  }

  validateState(name: string|number) {
    const { $dirty, $error } = this.$v.form[name] || {};
    return $dirty ? !$error : null;
  }

  resetForm() {
    this.form = {
      email: null,
      firstName: null,
      lastName: null 
    };

    this.$nextTick(() => {
      this.$v.$reset();
    });
  }

  onSubmit() {
    this.$v.form.$touch();
    if (this.$v.form.$anyError) {
      return;
    }

    this.showOverlay = true;
    this.$store.dispatch('auth/grantAdmin', this.form).then(res => {
      this.showOverlay = false;
      this.$emit('updateSecondPartnerAdmins');
      this.$store.dispatch('notify', {title: 'New Administrator', body: 'Invitation sent to ' + this.form.email, variant: 'success'});
      this.$bvModal.hide('modal-create-second-partner');
    })
    .catch(err => {
      this.showOverlay = false;
      console.log(err);
      this.$store.dispatch('notify', {title: 'Error occured', 
        body: `${err.data.message ? err.data.message : 'Error occured while trying to add new department admin'}`, variant: 'danger'});
    });
  }

  clearForm() {
    this.resetForm();
  }
}
